
import './App.css';
import Home from './home';
import Header from './header';

function App() {

  return (
    <div>
        <Header />
        <Home />
    </div>
  );
   
    
}

export default App;
