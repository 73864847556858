import React from "react";


const Home = () => {

    return (
        <div className="home-contents">
            <div className="hours">
            <h1>Hours</h1>
            <table>
                <tr>
                    <td>Monday</td>
                    <td>7pm - 1am</td>
                </tr>
                <tr>
                    <td>Tuesday</td>
                    <td>7pm - 1am</td>
                </tr>
                <tr>
                    <td>Wednesday</td>
                    <td>7pm - 1am</td>
                </tr>
                <tr>
                    <td>Thursday</td>
                    <td>7pm - 1am</td>
                </tr>
                <tr>
                    <td>Friday</td>
                    <td>7pm - 3am</td>
                </tr>
                <tr>
                    <td>Saturday</td>
                    <td>7pm - 3am</td>
                </tr>
                <tr>
                    <td>Sunday</td>
                    <td>Closed</td>
                </tr>
            </table>
            </div>
            <div className="contact">
                <h1>Address & Contact</h1>
                <p><b>Address: </b>Geyerstraße 18, 80469 München</p>
                <p><b>Phone: </b> 0176 98441816</p>  
            </div>
        </div>
        
    )

}

export default Home;