import React from "react";
import logo from './kooksLogo.png';

const Header = () => {

    return (
        <div className="bar">
                <img className="logo" src={logo} alt="kooks logo"/>           
        </div>
        
    )

}

export default Header;